export const dataTableAllowedExcecuteFields = {
  HorizontalLineExecute: () => import("./HorizontalLineExecute.vue"),
  CarouselExecute: () => import("./CarouselExecute.vue"),
  TableExecute: () => import("./TableExecute.vue"),
  CardExecute: () => import("./CardExecute.vue"),
  DateExecute: () => import("./DateExecute.vue"),
  DateTimeExecute: () => import("./DateTimeExecute.vue"),
  DateTimeRangeExecute: () => import("./DateTimeRangeExecute.vue"),
  DateRangeExecute: () => import("./DateRangeExecute.vue"),
  TimeExecute: () => import("./TimeExecute.vue"),
  LocationExecute: () => import("./LocationExecute.vue"),
  TimeRangeExecute: () => import("./TimeRangeExecute.vue"),
  FileExecute: () => import("./FileExecute.vue"),
  MultiLineTextExecute: () => import("./MultiLineTextExecute.vue"),
  SingleLineTextExecute: () => import("./SingleLineTextExecute.vue"),
  MultiSelectExecute: () => import("./MultiSelectExecute.vue"),
  NumberExecute: () => import("./NumberExecute.vue"),
  SelectExecute: () => import("./SelectExecute.vue"),
  YesOrNoExecute: () => import("./YesOrNoExecute.vue"),
  HeadingExecute: () => import("./HeadingExecute.vue"),
  CheckBoxExecute: () => import("./CheckBoxExecute.vue"),
  RadioExecute: () => import("./RadioExecute.vue"),
  ESignatureExecute: () => import("./ESignatureExecute.vue"),
  ParagraphExecute: () => import("./ParagraphExecute.vue"),
  GlobalVariableExecute: () => import("./GlobalVariableExecute.vue"),
  EntityVariableExecute: () => import("./EntityVariableExecute.vue"),
  EntityExecute: () => import("./EntityExecute.vue"),
  SingleLineContentExecute: () => import("./SingleLineContentExecute.vue"),
  AuthorizedSignatureExecute: () => import("./AuthorizedSignatureExecute.vue"),
  ImageExecute: () => import("./ImageExecute.vue"),
  VideoExecute: () => import("./VideoExecute.vue"),
  FormulaExecute: () => import("./FormulaExecute.vue"),
  PaymentExecute: () => import("./PaymentExecute.vue"),
  ActionButtonExecute: () => import("./ActionButtonExecute.vue"),
  HtmlExecute: () => import("./HtmlExecute.vue"),
  ListExecute: () => import("./ListExecute.vue"),
  DIVExecute: () => import("./DIVExecute.vue"),
  CurrencyTypesExecute: () => import("./CurrencyTypesExecute.vue"),
  NavBarExecute: () => import("./NavBarExecute.vue"),
  AggregateFunctionExecute: () => import("./AggregateFunctionExecute.vue"),
  PhoneCountryCodeExecute: () => import("./PhoneCountryCodeExecute.vue"),
  CurrencyExecute: () => import("./CurrencyExecute.vue"),
  QuestionExecute: () => import("./QuestionExecute.vue"),
  CheckBoxGroupExecute: () => import("./CheckBoxGroupExecute.vue"),
  AutoIncrementExecute: () => import("./AutoIncrementExecute.vue"),
  RandomTextExecute: () => import("./RandomTextExecute.vue"),
  WeekDaysExecute: () => import("./WeekDaysExecute.vue"),
  RadioButtonGroupExecute: () => import("./RadioButtonGroupExecute.vue"),
  DataTableViewExecute: () => import("./DataTableExecute.vue"),
  StarRatingExecute: () => import("./StarRatingExecute.vue"),
  PayVariableExecute: () => import("./PayVariableExecute.vue"),
  ConcatenateExecute: () => import("./ConcatenateExecute.vue"),
  EntityTableExecute: () => import("./EntityTableExecute.vue"),
  AudioExecute: () => import("./AudioExecute.vue"),
  CaptchaExecute: () => import("./CaptchaExecute.vue"),
  QRExecute: () => import("./QRExecute.vue"),
  QRReaderExecute: () => import("./QRReaderExecute.vue"),
  IconExecute: () => import("./IconExecute.vue"),
  CalenderExecute: () => import("./CalenderExecute.vue"),
  IntegrationExecute: () => import("./IntegrationExecute.vue"),
  IntegrationVariableExecute: () => import("./IntegrationVariableExecute.vue"),
};
